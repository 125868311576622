import { mapActions } from "vuex";

export default {
  data () {
    return {

    } ;
  },
  created () {
    this.logOut()
  },
  methods: {
    ...mapActions(["LogOut"]),
    logOut() {
      this.LogOut();
      this.$router.push('/notConnected');
    }
  }
}
