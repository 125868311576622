import { apiClient } from "./ApiClient";

const ops = "ops/";

export default {
  async assignTask() {
    const response = await apiClient.get(ops + "assign_task");
    return response.data;
  },

  async isOnline() {
    const response = await apiClient.get(ops + "agent/is_online");
    return response;
  },

  async finishTask(taskId, contactId, contactEmail, contactPhoneNumber, outputs) {
    const response = await apiClient.post(ops + "actions/finish_task", {
      task_id: taskId,
      contact_id: contactId,
      contact_email: contactEmail,
      contact_phone_number: contactPhoneNumber,
      outputs,
    });
    return response.data;
  },

  async notAnswered(taskId, contactId) {
    const response = await apiClient.post(ops + "actions/not_answered", {
      task_id: taskId,
      contact_id: contactId,
    });
    return response.data;
  },

  async escalate(taskId, contactId, message) {
    const response = await apiClient.post(ops + "actions/escalate", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 1,
      message,
    });
    return response.data;
  },

  async addComment(taskId, contactId, message) {
    const response = await apiClient.post(ops + "actions/comment", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 1,
      message,
    });
    return response.data;
  },

  async bookSlot(taskId, contactId, slotId) {
    const response = await apiClient.post(ops + "actions/book_slot", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 5,
      slot_id: slotId,
    });
    return response;
  },
  async getSlots(taskId, contactId) {
    const response = await apiClient.post(ops + "actions/get_slots", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 9,
    });
    return response.data;
  },

  async rejectCandidate(taskId, contactId) {
    const response = await apiClient.post(ops + "actions/reject_candidate", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 6,
    });
    return response.data;
  },

  async notInterested(taskId, contactId) {
    const response = await apiClient.post(ops + "actions/not_interested", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 7,
    });
    return response.data;
  },
  async callLater(taskId, contactId) {
    const response = await apiClient.post(ops + "actions/call_later", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 11,
    });
    return response.data;
  },
  async submitComment(contactId, content) {
    await apiClient.post(ops + "comment", {
      contact_id: contactId,
      content,
    });
  },

  async neverCallAgain(taskId, contactId) {
    const response = await apiClient.post(ops + "actions/never_call_again", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 8,
    });
    return response.data;
  },

  async makeCall(taskId, contactId) {
    const response = await apiClient.post(ops + "actions/make_call", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 4,
    });
    return response.data;
  },

  async pingEmail(taskId, contactId) {
    const response = await apiClient.post(ops + "actions/ping", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 2,
      sms: false,
    });
    return response;
  },
  async pingSms(taskId, contactId) {
    const response = await apiClient.post(ops + "actions/ping", {
      task_id: taskId,
      contact_id: contactId,
      action_id: 3,
      sms: true,
    });
    return response;
  },
  async reportBug(taskId, contactId, comment) {
    const response = await apiClient
      .post(ops + "actions/report_bug", {
        task_id: taskId,
        contact_id: contactId,
        action_id: 10,
        message: comment,
      })
      .catch((error) => {
        throw error;
      });
    return response;
  },
};
