<template>
  <v-col style="text-align: justify">
    <h3 class="text-center">Attributes</h3>
    <ul class="attributes-list">
      <li v-for="(label, key) in attributeLabels" :key="key">
        <template v-if="getAttribute(key)">
          <b>{{ label }}:</b> {{ getAttribute(key) }}
        </template>
      </li>
      <li v-if="isCourierSegment"><b>Admin courier link: </b><a :href="adminCourierLink" target="_blank">{{ adminCourierLink }}</a></li>
      <li v-if="getAttribute('fleet_user_info')"><b>Fleet Company Name:</b> {{ getAttribute('fleet_user_info').companyName }}</li>
      <li v-if="getAttribute('fleet_user_info')"><b>Fleet Company Phone Number:</b> {{ getAttribute('fleet_user_info').phone }}</li>
    </ul>
  </v-col>
</template>

<script>
export default {
  props: {
    attributes: {
      type: Array,
      required: true,
    },
    contactType: {
      type: String,
      default: null,
    },
  },
  computed: {
    attributeLabels() {
      return {
        "id": `${this.contactType} ID`,
        "name": "Name",
        "email": "Email",
        "phone_number": "Phone number",
        "city_code": "City code",
        "city_name": "City name",
        "reapplying_date": "Lead creation time",
        "last_utm_source": "UTM source",
        "transport_type": "Transport",
        "working_hours": "Working hours",
        "comments": "Comment",
        "fleet_user_id": "Fleet ID",
      };
    },
    adminCourierLink() {
      const id = this.getAttribute("id");
      return id ? `https://beta-admin.glovoapp.com/courier/${id}` : '';
    },
    isCourierSegment() {
      return this.contactType === "COURIER";
    },
  },
  methods: {
    getAttribute(key) {
      const attribute = this.attributes.find(attr => attr[0] === key);
      return attribute ? attribute[1] : null;
    },
  }
};
</script>

<style lang="scss" scoped>
.attributes-list {
  text-align: left;
}
</style>
