<template>
  <v-container class="mt-5">
    <h1>Connect to PACall</h1>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        color="#E19F17"
        required
      ></v-text-field>

      <v-text-field
        v-model="password"
        :rules="passwordRules"
        label="Password"
        type="password"
        color="#E19F17"
        required
      ></v-text-field>

      <div class="text-center">
        <v-btn
          :disabled="!valid"
          color="glovo-color"
          class="mr-4"
          @click="validate"
        >
          Validate
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script >
import { mapActions } from "vuex";
import UserServices from "@/services/UserServices";
import VueJwtDecode from "vue-jwt-decode";

export default {
  data() {
    return {
      valid: true,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },
  beforeMount() {},
  methods: {
    ...mapActions(["LogIn", "LogOut"]),
    validate() {
      this.$refs.form.validate();
      UserServices.login(this.email, this.password)
        .then((data) => {
          if (data) {
            const decodedToken = VueJwtDecode.decode(data.access_token);

            const User = {
              username: decodedToken.sub,
              scopes: decodedToken.scopes,
              token: data.access_token,
              cloudtalk_agent_id: data.cloudtalk_agent_id,
              tokenExpiration: decodedToken.exp,
              user_id: data.id,
            };

            this.LogIn(User).then(() => this.$router.push({ name: "Home" }));
          } else {
            this.$emit("alertEvent", true, "Wrong credentials");
          }
          // TODO: Assign Variable
        })
        .catch(() => {});
    },
  },
};
</script>