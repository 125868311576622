<template>
  <v-app>
    <NavBar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "App",
  data: () => ({}),
  watch: {},
  methods: {},
  components: { NavBar },
};
</script>


<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat");

#app {
  font-family: "Montserrat";
}

h1 {
  font-family: inherit;
  font-size: 2em !important;
  text-align: center;
}

a {
  color: #333;
  text-decoration: none;
}
</style>
