import UserServices from "@/services/UserServices";

const state = {
  user: null,
  username: null,
  authToken: null,
  tokenExpiration: null,
  userType: null,
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_USERNAME(state, username) {
    state.username = username;
  },
  SET_USER_TYPE(state, userType) {
    state.userType = userType;
  },
  SET_AUTH_TOKEN(state, token) {
    state.authToken = token;
  },
  SET_TOKEN_EXPIRATION(state, expiration) {
    state.tokenExpiration = expiration;
  },
};

const actions = {
  async LogIn({ commit }, user) {
    UserServices.setUserToken(user.token);
    await commit("SET_AUTH_TOKEN", user.token);
    await commit("SET_USER", user);
    await commit("SET_USERNAME", user.username);
    await commit("SET_TOKEN_EXPIRATION", user.tokenExpiration);
    const scopes = user.scopes;
    if (scopes.includes("Ops")) {
      await commit("SET_USER_TYPE", "Ops");
      return;
    }
  },

  LogOut({ commit }) {
    UserServices.logout();
    localStorage.removeItem("user");
    commit("SET_AUTH_TOKEN", null);
    commit("SET_USER", null);
    commit("SET_USERNAME", null);
    commit("SET_TOKEN_EXPIRATION", null);
  },
};

const getters = {
  isAuthenticated: (state) => !!state.authToken,
  AgentName: (state) => state.username,
  AuthToken: (state) => state.authToken,
  TokenExpirationTime: (state) => state.tokenExpiration,
  userType: (state) => state.userType,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
