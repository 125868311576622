<template>
  <div>
    <v-dialog v-model="dialog_bug" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Report a bug
        </v-card-title>

        <v-card-text>
          <v-textarea v-model="bug_comment"></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog_bug = false">
            Close
          </v-btn>
          <v-btn color="success" text @click="sendBug()"> Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn color="error" text @click="reportBug(entity_id)"
      ><v-icon> mdi-bug </v-icon>
    </v-btn>
  </div>
</template>

<script>
import OpsServices from "@/services/OpsServices";
export default {
  props: {
    entity_id: {
      type: Number,
      require: true,
    },
    contactId: {
      type: Number,
      require: false,
    },
    pacall_type: {
      type: String,
      default: "OPS",
    },
  },
  data() {
    return {
      dialog_bug: false,
      bug_comment: "",
    };
  },
  methods: {
    reportBug(taskId) {
      this.dialog_bug = true;
      this.dialog_bug_task_id = taskId;
    },
    sendBug() {
      OpsServices.reportBug(this.entity_id, this.contactId, this.bug_comment)
        .then(() => {
          // Handle response
          this.dialog_bug = false;
        })
        .catch((error) => {
          // Handle Error
          console.log(error);
          this.alert = true;
          this.alertMessage = error;
        });
    },
  },
};
</script>

<style>
</style>