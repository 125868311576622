import axios from "axios";
import { hostname } from "@/config/variables";
import store from "../store";
import router from "../router";

export const apiClient = axios.create({
  baseURL: hostname,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

apiClient.interceptors.response.use(undefined, function(error) {
  if (error) {
    if (error.response.status === 401) {
      store.dispatch("LogOut");
      router.push({ name: "NotConnected" });
    }
  }
});
