<template>
  <v-col>
    <h3>Slot Managements</h3>

    <v-container v-if="!slotsLoaded">
      <div class="loader"></div>
    </v-container>
    <v-container v-else>
      <v-tabs v-model="slotsModel" slider-color="#ffc244">
        <v-tab v-for="(i, index) in slots" :key="index">{{ i.date }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="slotsModel">
        <v-tab-item v-for="(i, indexDay) in slots" :key="indexDay">
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr style="width: 80%">
                    <th class="text-center">Slot time</th>
                    <th class="text-center">Booked</th>
                  </tr>
                </thead>
                <tbody child-flex>
                  <tr v-for="(is, indexSlot) in i.slots" v-bind:key="indexSlot">
                    <td class="text-center">
                      {{ is.start_time.split("T")[1] }} -
                      {{ is.finish_time.split("T")[1] }}
                    </td>
                    <td
                      :style="
                        getSlotColor(
                          is.is_free_capacity && !is.is_past,
                          is.is_courier_booked,
                          is.is_open_slot,
                          is.is_past
                        )
                      "
                      :class="
                        isAvailable(
                          is.is_free_capacity && !is.is_past,
                          is.is_courier_booked,
                          is.is_open_slot,
                          is.is_past
                        )
                      "
                      @click="
                        manageSlot(
                          is.is_free_capacity && !is.is_past,
                          is.is_courier_booked,
                          is.id,
                          is.is_open_slot,
                          is.is_past,
                          indexDay,
                          indexSlot
                        )
                      "
                    ></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-col>
</template>

<script>
import OpsServices from "@/services/OpsServices.js";

export default {
  props: {
    taskId: {
      type: Number,
      required: true,
    },
    contactId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      can_overbook: true,
      can_unbook: false,
      slotsModel: [],
      slots: [],
      slotsLoaded: false,
    };
  },
  created() {
    this.getSlots();
  },
  methods: {
    async getSlots() {
      // TODO Change slots to come from endpoint call
      this.slots = await OpsServices.getSlots(this.taskId, this.contactId);
      this.slotsLoaded = true;
    },
    getSlotColor(is_free_capacity, is_courier_booking, open_slot, is_past) {
      if (this.can_overbook && open_slot && !is_past && !is_courier_booking) {
        return "background-color: #fff";
      } else {
        if (is_courier_booking) {
          return "background-color: #2ABB9B";
        } else if (is_free_capacity) {
          return "background-color: #fff";
        } else {
          return "background-color: #808080";
        }
      }
    },
    isAvailable(is_free_capacity, is_courier_booked, is_open_slot, is_past) {
      if (
        ((!is_courier_booked && is_free_capacity) ||
          (this.can_overbook && is_open_slot) ||
          (is_courier_booked && this.can_unbook)) &&
        !is_past
      ) {
        return "slot-action";
      }
    },
    manageSlot(
      is_free_capacity,
      is_courier_booked,
      slot_id,
      is_open_slot,
      is_past,
      indexDay,
      indexSlot
    ) {
      if (
        ((!is_courier_booked && is_free_capacity) ||
          (this.can_overbook && is_open_slot && !is_courier_booked)) &&
        !is_past
      ) {
        OpsServices.bookSlot(this.taskId, this.contactId, slot_id)
          .then((response) => {
            console.log(response);
            if (response.status == 200) {
              this.slots[indexDay].slots[indexSlot].is_courier_booked = true;
            }

            if (response.status == 203) {
              this.$emit("alertEvent", {
                message: "Slot without more capacity",
                type: "error",
                active: true,
              });
            }
          })
          .catch((error) => {
            this.$emit("alertEvent", {
              message: error,
              type: "error",
              active: true,
            });
          });
      }
    },
  },
};
</script>

<style>
</style>