<template>
  <div>
    <v-app-bar app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <v-img
          alt="Glovo Logo"
          contain
          class="app-bar-logo"
          src="https://glovoapp.com/images/glovo-white.svg"
          transition="scale-transition"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn href="/" text class="hidden-sm-and-down">
        <span class="mr-2">{{
          userType() != null ? "PACall " + userType() : "PACall"
        }}</span>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute bottom temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <router-link to="/">
            <v-list-item>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/logout">
            <v-list-item>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="task_history_dialog" width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Task History
        </v-card-title>
        <div style="text-align: justify">
          <v-data-table
            :headers="headers"
            :items="task_history"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.order_id`]="{ item }">
              <a :href="item.link">{{ item.order_id }}</a>
            </template></v-data-table
          >
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="task_history_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="note_dialog" width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2"> Notes </v-card-title>

        <v-card-text>
          <v-textarea
            v-model="user_notes"
            @change="updateNotesOnChange"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="note_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="add_order_dialog" width="50%">
      <v-card>
        <v-card-title class="headline grey lighten-2"> Add Order </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="order_id"
            label="Order ID #"
            type="number"
            color="#E19F17"
            required
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" text @click="add_order_dialog = false">
            Close
          </v-btn>

          <v-btn color="primary" text @click="newTask"> Add </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    headers: [
      {
        text: "Order id",
        align: "start",
        sortable: false,
        value: "order_id",
      },
      { text: "Order Status", value: "status" },
      { text: "Task termination time", value: "task_termination_time" },
    ],
    drawer: false,
    group: null,
    homeLink: "/",
    note_dialog: false,
    add_order_dialog: false,
    user_notes: store.getters.getNotes,
    task_history: [],
    task_history_dialog: false,
    order_id: null,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    ...mapGetters(["userType"]),
    ...mapActions(["updateNote"]),
    updateNotesOnChange() {
      console.log(this.user_notes);
      this.updateNote(this.user_notes);
    },
    newTask() {
      console.log(this.order_id);
      this.$refs.mainBody.manualAssignTask(this.order_id);
      this.add_order_dialog = false;
    },
  },
};
</script>

