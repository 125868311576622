import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import notes from "./modules/notes";
import ui from "./modules/ui";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    notes,
    ui,
  },
  plugins: [createPersistedState()],
});
