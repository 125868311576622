const state = {
  notes: ''
};

const getters = {
  getNotes: (state) => state.notes,
};

const actions = {
  async updateNote({ commit }, notes) {
    await commit("setNotes", notes );
  },

  async deleteNote({ commit }) {
    await commit("setNotes", '' );
  },
};

const mutations = {
  setNotes(state, notes) {
    state.notes = notes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
