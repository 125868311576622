<template>
  <div>
    <v-alert v-if="alert.active" dense border="left" :type="alert.type">
      {{ alert.message }}
    </v-alert>

    <Ops v-if="userType() == 'Ops'" @alertEvent="alertEvent" />
  </div>
</template>


<script >
import store from "@/store";
import Login from "@/components/Auth/Login";
import Ops from "@/components/Ops/OpsComponent.vue";

import { mapActions, mapGetters } from "vuex";
import UserServices from "@/services/UserServices";

export default {
  data() {
    return {
      agentName: store.getters.AgentName,
      alert: {
        message: null,
        type: null,
        active: false,
      },
    };
  },
  beforeMount() {
    if (!this.isAuthenticated()) {
      this.setUser();
    } else {
      this.assertUser();
    }
  },
  methods: {
    ...mapGetters(["AuthToken", "isAuthenticated", "userType"]),
    ...mapActions(["LogIn", "LogOut"]),
    setUser() {
      const authUser = Login.methods.getLoginToken();
      this.LogIn(authUser)
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "NotConnected" });
        });
    },
    assertUser() {
      UserServices.setUserToken(this.AuthToken());
    },
    alertEvent(newAlert) {
      this.alert = newAlert;
    },
  },

  components: { Ops },
  watch: {
    alert(new_val) {
      if (new_val.active) {
        setTimeout(() => {
          new_val.active = false;
        }, 3000);
      }
    },
  },
};
</script>

<style>
.info-text {
  max-width: 767px;
  text-align: center;
  margin: auto;
}
</style>
