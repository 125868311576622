const state = {
  loading: false,
};

const getters = {
  loading: (state) => state.loading,
};

const actions = {
  setLoading({ commit }, loading) {
    commit("SET_LOADING", loading);
  },
};

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
