import { apiClient } from "./ApiClient";

const ops = "ops/";

export default {
  setUserToken(token) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  async login(email, password) {
    const response = await apiClient
      .post(ops + "login", {
        Authorization: "Basic " + btoa(email + ":" + password)
      })
      .catch(error => {
        throw error;
      });
    return response.data;
  },
  async logout() {
    return;
  }
};
