var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[_c('h3',[_vm._v("Slot Managements")]),(!_vm.slotsLoaded)?_c('v-container',[_c('div',{staticClass:"loader"})]):_c('v-container',[_c('v-tabs',{attrs:{"slider-color":"#ffc244"},model:{value:(_vm.slotsModel),callback:function ($$v) {_vm.slotsModel=$$v},expression:"slotsModel"}},_vm._l((_vm.slots),function(i,index){return _c('v-tab',{key:index},[_vm._v(_vm._s(i.date))])}),1),_c('v-tabs-items',{model:{value:(_vm.slotsModel),callback:function ($$v) {_vm.slotsModel=$$v},expression:"slotsModel"}},_vm._l((_vm.slots),function(i,indexDay){return _c('v-tab-item',{key:indexDay},[[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticStyle:{"width":"80%"}},[_c('th',{staticClass:"text-center"},[_vm._v("Slot time")]),_c('th',{staticClass:"text-center"},[_vm._v("Booked")])])]),_c('tbody',{attrs:{"child-flex":""}},_vm._l((i.slots),function(is,indexSlot){return _c('tr',{key:indexSlot},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(is.start_time.split("T")[1])+" - "+_vm._s(is.finish_time.split("T")[1])+" ")]),_c('td',{class:_vm.isAvailable(
                        is.is_free_capacity && !is.is_past,
                        is.is_courier_booked,
                        is.is_open_slot,
                        is.is_past
                      ),style:(_vm.getSlotColor(
                        is.is_free_capacity && !is.is_past,
                        is.is_courier_booked,
                        is.is_open_slot,
                        is.is_past
                      )),on:{"click":function($event){return _vm.manageSlot(
                        is.is_free_capacity && !is.is_past,
                        is.is_courier_booked,
                        is.id,
                        is.is_open_slot,
                        is.is_past,
                        indexDay,
                        indexSlot
                      )}}})])}),0)]},proxy:true}],null,true)})]],2)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }