<template>
  <v-container class="networkError text-center">
    <h1>Uh-Oh!</h1>
    <h3>It looks like you are not logged in. Try to login again.</h3>
    <v-btn elevation="2" outlined class="call-btn" :to="{ name: 'LoginBasic' }"
      >Log in to PACall Ops</v-btn
    >
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  ...mapGetters(["userType"]),
  beforeMount() {
      return;
  },
};
</script>

