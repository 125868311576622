<template>
  <div class="comments">
    <h3 class="text-center">Comments</h3>
    <h5 v-if="lastUpdateTime" class="last-updated-time">
      Last updated: {{ lastUpdateTime }}
    </h5>
    <v-textarea
      class="comments-box"
      :value="value"
      counter="1000"
      :rules="[
        (v) =>
          (v || '').length <= 1000 || 'Comment must be 1000 characters or less',
      ]"
      @input="(value) => $emit('input', value.slice(0, 1000))"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    lastUpdateTime: String || null,
  },
};
</script>

<style scoped>
.comments > .last-updated-time {
  text-align: center;
  color: #c4c4c4;
  font-size: 10px;
  font-style: italic;
}
.comments > .comments-box {
  padding: 0;
}
</style>