import Vue from "vue";
import Router from "vue-router";
import Home from "./views/HomeComponent.vue";
import Ops from "./components/Ops/OpsComponent.vue";
import Login from "./views/LoginComponent.vue";
import OneLogin from "./components/Auth/OneLogin.vue";
import Logout from "./components/Auth/LogoutComponent.vue";
import NotConnected from "./views/NotConnected.vue";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        title: "PACall",
        requiresAuth: true,
      },
    },
    {
      path: "/ops",
      name: "Ops",
      component: Ops,
      meta: {
        requiresAuth: true,
        title: "PACall",
      },
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
    },
    {
      path: "/login",
      name: "Login",
      component: OneLogin,
      meta: {
        title: "PACall",
      },
    },
    {
      path: "/loginb",
      name: "LoginBasic",
      component: Login,
      meta: {
        title: "PACall",
      },
    },
    {
      path: "/notConnected",
      name: "NotConnected",
      component: NotConnected,
      meta: {
        guest: true,
        title: "PACall",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (
    typeof store.getters.userType !== "undefined" &&
    store.getters.userType !== null &&
    !to.name.includes("Login")
  ) {
    document.title += " " + store.getters.userType;
  }
  next();
});

export default router;
