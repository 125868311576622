<template>
  <v-col class="text-center">
    <v-row class="text-center">
      <v-col>
        <v-tooltip top v-if="taskButtons.finish_task">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              elevation="2"
              outlined
              class="call-btn"
              v-bind="attrs"
              v-on="on"
              @click="finishTask"
              >{{ taskButtons.finish_task.display_name }}</v-btn
            >
          </template>
          <span>{{ taskButtons.finish_task.description }}</span>
        </v-tooltip>

        <v-tooltip top v-if="taskButtons.not_answered">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              elevation="2"
              outlined
              class="call-btn"
              v-bind="attrs"
              v-on="on"
              @click="notAnswered"
              >{{ taskButtons.not_answered.display_name }}</v-btn
            >
          </template>
          <span>{{ taskButtons.not_answered.description }}</span>
        </v-tooltip>

        <v-tooltip top v-if="taskButtons.make_call">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              elevation="2"
              outlined
              class="call-btn"
              v-bind="attrs"
              v-on="on"
              @click="makeCall"
              >{{ taskButtons.make_call.display_name }}</v-btn
            >
          </template>
          <span>{{ taskButtons.make_call.description }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              elevation="2"
              outlined
              class="call-btn"
              v-bind="attrs"
              v-on="on"
              @click="callLater"
              >{{ taskButtons?.call_later?.display_name || "Call later" }}</v-btn
            >
          </template>
          <span>{{ taskButtons?.call_later?.description || "Call later" }}</span>
        </v-tooltip>

        <v-tooltip top v-if="taskButtons.reminder_email">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              v-if="contactType == 'CANDIDATE'"
              elevation="2"
              class="call-btn"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="pingEmail"
              >{{ taskButtons.reminder_email.display_name }}</v-btn
            ></template
          >
          <span>{{ taskButtons.reminder_email.description }}</span>
        </v-tooltip>

        <v-tooltip top v-if="taskButtons.reminder_sms">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="contactType == 'CANDIDATE'"
              :disabled="loading"
              elevation="2"
              outlined
              class="call-btn"
              v-bind="attrs"
              v-on="on"
              @click="pingSms"
              >{{ taskButtons.reminder_sms.display_name }}</v-btn
            ></template
          >
          <span>{{ taskButtons.reminder_sms.description }}</span>
        </v-tooltip>

        <v-dialog
          v-model="slotBookingButton"
          width="50%"
          v-if="taskButtons.book_slots && contactType == 'COURIER'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="2" class="call-btn" outlined v-bind="attrs" v-on="on">
              {{ taskButtons.book_slots.display_name }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{ taskButtons.book_slots.display_name }}
            </v-card-title>

            <v-card-text>
              {{ taskButtons.book_slots.description }}
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn color="primary" text @click="slotBookingButton = false"> Close </v-btn>
            </v-card-actions>

            <BookSlots :taskId="taskId" :contactId="contactId" @alertEvent="alertEvent" />
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <v-dialog v-model="escalateButton" width="30%" v-if="taskButtons.escalate_call">
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="2" class="call-btn" outlined v-bind="attrs" v-on="on">
              {{ taskButtons.escalate_call.display_name }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{ taskButtons.escalate_call.display_name }}
            </v-card-title>

            <v-card-text>
              {{ taskButtons.escalate_call.description }}
              <v-text-field
                v-model="escalateText"
                :label="taskButtons.escalate_call.display_name + ' text here'"
                clearable
              ></v-text-field>
              <br />
              <v-btn elevation="2" @click="escalate">Submit</v-btn>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="escalateButton = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-tooltip top v-if="taskButtons.not_interested">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              elevation="2"
              class="call-btn"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="notInterested"
              >{{ taskButtons.not_interested.display_name }}</v-btn
            >
          </template>
          <span>{{ taskButtons.not_interested.description }}</span>
        </v-tooltip>

        <v-tooltip top v-if="taskButtons.reject">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="contactType == 'CANDIDATE'"
              :disabled="loading"
              elevation="2"
              outlined
              v-bind="attrs"
              v-on="on"
              class="call-btn"
              @click="rejectCandidate"
              >{{ taskButtons.reject.display_name }}</v-btn
            >
          </template>

          <span>{{ taskButtons.reject.description }}</span>
        </v-tooltip>

        <v-tooltip top v-if="taskButtons.never_call_again">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              elevation="2"
              outlined
              v-bind="attrs"
              v-on="on"
              class="call-btn"
              @click="neverCallAgain"
              >{{ taskButtons.never_call_again.display_name }}</v-btn
            >
          </template>

          <span>{{ taskButtons.never_call_again.description }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import BookSlots from "./BookSlots.vue";
import OpsServices from "@/services/OpsServices.js";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { BookSlots },
  props: {
    contactType: {
      type: String,
      required: true,
    },
    taskId: {
      type: Number,
      required: true,
    },
    contactId: {
      type: Number,
      required: true,
    },

    taskButtons: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaders: {},
      escalateButton: false,
      escalateText: "",
      slotBookingButton: false,
    };
  },
  computed: {
    ...mapGetters("ui", ["loading"]),
  },
  beforeMount() {
    this.setupLoaders();
  },
  created() {},
  methods: {
    ...mapActions("ui", ["setLoading"]),
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async pingEmail() {
      this.setLoading(true);
      await OpsServices.pingEmail(this.taskId, this.contactId);
      this.setLoading(false);
    },
    async pingSms() {
      this.setLoading(true);
      await OpsServices.pingSms(this.taskId, this.contactId);
      this.setLoading(false);
    },
    async makeCall() {
      this.setLoading(true);
      await OpsServices.makeCall(this.taskId, this.contactId);
      this.setLoading(false);
    },
    async bookSlot(slot_id) {
      await OpsServices.bookSlot(this.taskId, this.contactId, slot_id);
    },
    callLater() {
      this.$emit("callLater");
    },
    async escalate() {
      await OpsServices.escalate(this.taskId, this.contactId, this.escalateText);
      this.escalateButton = false;
    },
    async rejectCandidate() {
      this.$emit("rejectCandidate");
    },
    async notInterested() {
      this.$emit("notInterested");
    },
    async neverCallAgain() {
      this.$emit("neverCallAgain");
    },
    async finishTask() {
      this.$emit("finishTask");
    },
    async notAnswered() {
      this.$emit("notAnswered");
    },
    alertEvent(alert) {
      this.$emit("alertEvent", alert);
    },

    setupLoaders() {
      this.loaders = {};
      Object.entries(this.taskButtons).forEach(([key]) => {
        this.loaders[key] = false;
      });
      this.loaders = {
        ...this.loaders,
      };
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.call-btn {
  font-size: 0.7em;
  margin: 10px;
  padding: 5px 7px;
}
</style>
