<template>
  <div>
    <v-alert v-model="alert" border="left" close-text="Close Alert" type="error" dark dismissible>
      {{ alertMessage }}
    </v-alert>
    <v-row v-if="loading">
      <v-col>
        <div class="loader-box">
          <div class="loader"></div>
        </div>
      </v-col>
    </v-row>
    <v-spacer />
    <v-container fluid style="padding: 25px; background: #fafafa">
      <v-row>
        <v-col>
          <div v-if="taskId">
            <v-row>
              <v-col class="text-center">
                <h3>{{ this.taskName }}</h3>
              </v-col>
              <ReportBug :entity_id="taskId" :contactId="contactId" pacall_type="OPS" />
            </v-row>
            <v-row>
              <TaskButtons
                v-if="taskId"
                :taskId="taskId"
                :contactId="contactId"
                :contactType="contactType"
                :taskButtons="buttons"
                @finishTask="finishTask"
                @notAnswered="notAnswered"
                @alertEvent="alertEvent"
                @callLater="callLater"
                @rejectCandidate="rejectCandidate"
                @neverCallAgain="neverCallAgain"
                @notInterested="notInterested"
              />
            </v-row>
            <v-row>
              <v-col md="6">
                <v-card>
                  <h3 class="text-center">Script</h3>
                  <div v-html="script" class="script"></div>
                </v-card>

                <v-card>
                  <h3 class="text-center">F.A.Q</h3>
                  <div v-html="faq" class="script"></div>
                </v-card>
              </v-col>
              <v-col>
                <v-row>
                  <v-col>
                    <v-card>
                      <Attributes :attributes="attributes" :contactType="contactType" />
                    </v-card>
                    <v-card>
                      <comments
                        v-model="commentContent"
                        :last-update-time="commentLastUpdateTime"
                      />
                    </v-card>
                    <v-card>
                      <Inputs ref="InputsRef" :inputs="inputs" />
                    </v-card>
                  </v-col>
                  <v-col>
                    <iframe
                      style="width: 100%; height: 750px; border: none"
                      src="https://phone.cloudtalk.io"
                      allow="microphone"
                    ></iframe>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-col class="text-center">
              <v-btn class="add-task-btn" @click="assignTask()"> Get New Task </v-btn>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Inputs from "./InputsComponent.vue";
import Attributes from "./AttributesComponent.vue";
import ReportBug from "../ReportBug.vue";
import TaskButtons from "./TaskButtons.vue";
import OpsServices from "@/services/OpsServices";
import Comments from "./CommentsComponent.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Attributes, TaskButtons, Inputs, ReportBug, Comments },
  data() {
    return {
      alert: false,
      alertMessage: "",
      taskId: null,
      contactId: null,
      contactEmail: "",
      contactPhoneNumber: "",
      contactType: null,
      taskName: null,
      task: {},
      script: null,
      faq: null,
      buttons: [],
      inputs: [],
      attributes: [],
      timedOperations: null,
      commentContent: "",
      commentLastUpdateTime: null,
    };
  },
  computed: {
    ...mapGetters("ui", ["loading"]),
  },
  created() {
    this.timedOperations = setInterval(() => {
      this.isOnline();
    }, 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timedOperations);
  },
  methods: {
    ...mapActions("ui", ["setLoading"]),
    async assignTask() {
      this.setLoading(true);
      OpsServices.assignTask()
        .then((data) => {
          if (data) {
            this.taskId = data.task_id;
            this.contactId = data.contact.contactLocal.id;
            this.script = data.task.script.script;
            this.faq = data.task.script.faq;
            this.attributes = [
              ...Object.entries(data.contact.contactExternal),
              ...Object.entries(data.contact.contactLocal),
            ];
            this.inputs = data.task.segment.inputs;
            this.buttons = data.task.segment.buttons;
            this.contactType = data.contact.contactLocal.entity_type;
            this.contactEmail = data.contact.contactExternal.email;
            this.contactPhoneNumber = data.contact.contactExternal.phone_number;
            this.taskName = data.task.segment.name;
            if (data?.comment) {
              this.commentContent = data.comment.content;
              this.commentLastUpdateTime = data.comment.last_update_time;
            } else {
              this.commentContent = "";
              this.commentLastUpdateTime = null;
            }

            this.buttons = data.task.segment.buttons.reduce(
              (obj, item) =>
                Object.assign(obj, {
                  [item.button_type]: {
                    display_name: item.display_name,
                    description: item.description,
                  },
                }),
              {}
            );
            return;
          }

          this.$emit("alertEvent", {
            message: "No task found",
            type: "warning",
            active: true,
          });
        })
        .catch((error) => {
          this.$emit("alertEvent", {
            message: error,
            type: "error",
            active: true,
          });
        })
        .finally(() => {
          this.setLoading(false);
        });
    },
    alertEvent(alert) {
      this.$emit("alertEvent", alert);
    },
    async finishTask() {
      this.setLoading(true);

      this.$refs.InputsRef.submit()
        .then(async (outputs) => {
          await this.submitComment();
          await OpsServices.finishTask(
            this.taskId,
            this.contactId,
            this.contactEmail,
            this.contactPhoneNumber,
            outputs
          );
        })
        .catch(() => {
          this.$emit("alertEvent", {
            message: "Inputs Error",
            type: "error",
            active: true,
          });
        })
        .finally(() => {
          this.setLoading(false);
          this.taskId = null;
        });
    },

    async notAnswered() {
      await this.submitAction(OpsServices.notAnswered);
    },
    async rejectCandidate() {
      await this.submitAction(OpsServices.rejectCandidate);
    },
    async callLater() {
      await this.submitAction(OpsServices.callLater);
    },
    async neverCallAgain() {
      await this.submitAction(OpsServices.neverCallAgain);
    },
    async notInterested() {
      await this.submitAction(OpsServices.notInterested);
    },
    async submitAction(action) {
      try {
        this.setLoading(true);
        await this.submitComment();
        await action(this.taskId, this.contactId);
      } catch (error) {
        this.$emit("alertEvent", {
          message: error,
          type: "error",
          active: true,
        });
      } finally {
        this.setLoading(false);
        this.taskId = null;
      }
    },
    async submitComment() {
      await OpsServices.submitComment(this.contactId, this.commentContent);
    },
    async isOnline() {
      await OpsServices.isOnline();
    },
  },
};
</script>

<style src="./Ops.css"></style>
