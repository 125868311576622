<template>
  <v-col>
    <h3>Inputs</h3>
    <v-form>
      <div v-for="input in inputs" :key="input.id + 'id'">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-if="input.input_type == 'text'"
              v-bind="attrs"
              v-on="on"
              v-model="outputs[input.id]"
              :error-messages="errorMessages(input)"
              :label="input.display_name"
              :required="input.required"
              @input="$v.outputs[input.id].$touch()"
              @blur="$v.outputs[input.id].$touch()"
            ></v-text-field>
            <v-select
              v-else-if="input.input_type == 'select'"
              v-model="outputs[input.id]"
              :items="input.options.concat(['Unselect'])"
              :error-messages="errorMessages(input)"
              :label="input.display_name"
              :required="input.required"
              @change="$v.outputs[input.id].$touch()"
              @blur="$v.outputs[input.id].$touch()"
            ></v-select>
            <v-radio-group
              v-else-if="input.input_type == 'radio'"
              v-model="outputs[input.id]"
              :label="input.display_name"
              :mandatory="input.required"
            >
              <v-radio
                v-for="option in input.options"
                :key="option + 'id'"
                :label="option"
                :value="option"
              ></v-radio>
              <v-radio
                v-if="!input.required"
                key="Noneid"
                label="unselect"
                @click="uncheckRadio(input.id)"
              ></v-radio>
            </v-radio-group>
            <v-checkbox
              v-bind="attrs"
              v-on="on"
              v-else-if="input.input_type == 'checkbox'"
              v-model="outputs[input.id]"
              :error-messages="errorMessages(input)"
              :label="input.display_name"
              :required="input.required"
              @change="$v.outputs[input.id].$touch()"
              @blur="$v.outputs[input.id].$touch()"
            ></v-checkbox>
          </template>
          <span>{{ input.description }}</span>
        </v-tooltip>
      </div>
    </v-form>
  </v-col>
</template>


<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    inputs: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    outputs: {},
  }),

  validations() {
    let validations = {};
    validations.outputs = this.setupValidation();
    return validations;
  },
  beforeMount() {
    this.setupOutput();
  },

  computed: {},

  methods: {
    errorMessages(input) {
      const errors = [];
      if (!this.$v.outputs[input.id].$dirty) return errors;
      if (this.$v.outputs[input.id].minLength !== undefined) {
        !this.$v.outputs[input.id].minLength &&
          errors.push(
            input.display_name + " must be at least 3 characters long"
          );
      }
      if (this.$v.outputs[input.id].required !== undefined) {
        !this.$v.outputs[input.id].required &&
          errors.push(input.display_name + " is required");
      }
      if (this.$v.outputs[input.id].checked !== undefined) {
        !this.$v.outputs[input.id].checked &&
          errors.push(input.display_name + " is required");
        return errors;
      }
      return errors;
    },
    setupOutput() {
      this.output = this.inputs.reduce(
        (o, input) => ({ ...o, [input.id]: null }),
        {}
      );
    },
    uncheckRadio(inputId) {
      this.outputs[inputId] = null;
    },

    setupValidation() {
      let outputs = {};
      for (const input of this.inputs) {
        if (input.required) {
          outputs[input.id] = { required };
        } else {
          outputs[input.id] = {};
        }
        if (input.input_type === "text") {
          outputs[input.id]["minLength"] = minLength(3);
        }
        if (input.input_type === "checkbox") {
          outputs[input.id]["checkbox"] = {
            checked(val) {
              return val;
            },
          };
        }
      }
      return outputs;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        throw Error;
      }
      return this.outputs;
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    },
  },
};
</script>
