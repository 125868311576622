import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueCookie from "vue-cookie";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);

Vue.use(VueCookie);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBqhBG_YQ-Zo0rUQiLYuWh9lovZcMexmOs",
    libraries: ["geometry", "places"],
  },
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
