import VueJwtDecode from "vue-jwt-decode";
import { mapActions, mapGetters } from "vuex";

import Cookies from "../../helpers/Cookies";

export default {
  beforeMount() {
    return;
  },
  methods: {
    ...mapActions(["LogIn", "LogOut"]),
    ...mapGetters(["userType"]),
    getLoginToken() {
      const urlParams = new URLSearchParams(window.location.search);

      const cookieUser = Cookies.get("Authorization");
      const cookieToken = cookieUser ? cookieUser.split(" ")[1] : null;
      const token = urlParams.get("token") || cookieToken || "";

      if (!token) {
        return null;
      }
      const decodedToken = VueJwtDecode.decode(token);
      return {
        username: decodedToken.sub,
        scopes: decodedToken.scopes,
        token: token,
        tokenExpiration: decodedToken.exp,
      };
    },
    async setUser() {
      const authUser = this.getLoginToken();
      this.LogIn(authUser).catch((error) => {
        console.log(error);
      });
    },
  },
};
